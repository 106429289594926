import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const PORTFOLIO_ITEM_QUERY = graphql`
    query PortfolioItems {
        allPostsJson {
            edges {
                node {
                    id
                    title
                    description
                    url
                    image {
                        name
                        src {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const PortfolioListingWrapper = styled.article`
    grid-area: auto / 1 / auto / -1;

    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: .5rem;
    grid-row-gap: 1rem;

    header{
        grid-area: 1 / 1 / 1 / -1;
        h2{
            margin: 0;
            font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
            font-weight: 400;
            font-size: .875rem;
            line-height: 1.25;
            text-transform: uppercase;
            position: sticky;
            top: 2rem;
        }
    }
    section{
        grid-area: auto / 1 / auto / 9;
        display: grid;
        grid-row-gap: 2rem;
    }
    .item-wrapper {
        p{
            font-size: 1rem;
        }
        h3{
            font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.25;
            text-transform: uppercase;
            font-style: normal;
            text-decoration: none;
            margin: .5rem 0 0.25rem 0;

            span{
                position: relative;
            }
            span:after {
                content: '';
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #1a1a1a;
                transform: scale3d(0, 1, 1);
                transform-origin: center right;
                transition: transform .75s cubic-bezier(.86,0,.07,.995); 
            }
        }
        a{
            display: block;
        }
        a.touching{
            h3 span:after{
                transform: scale3d(1, 1, 1);
                transform-origin: center left;
            }
            .item-browser-wrapper{
                .dot-close {
                    background: #EC655A;
                }
                .dot-min {
                    background: #E0C14C;
                }
                .dot-max {
                    background: #71BF47;
                }
            }
        }
        @media(hover: hover) and (pointer: fine) {
            a:hover{
                h3 span:after{
                    transform: scale3d(1, 1, 1);
                    transform-origin: center left;
                }
                .item-browser-wrapper{
                    .dot-close {
                        background: #EC655A;
                    }
                    .dot-min {
                        background: #E0C14C;
                    }
                    .dot-max {
                        background: #71BF47;
                    }
                }
            }
        }
        
    }
    .item-browser-wrapper{
        border: 1px solid #1a1a1a;
        background-color: #fffffa;
        border-radius: 5px;
        padding-top: 9px;
        overflow: hidden;
        position: relative;

        .browser-bar {
            height: 9px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 1px solid #1a1a1a;
    
            .dot-close, .dot-min, .dot-max {
                position: absolute;
                top: 2px;
                height: 4px;
                width: 4px;
                border-radius: 100%;
                background: #1a1a1a;
                transition: 0.15s ease background;
            }
            .dot-close {
                left: 3px;
            }
            .dot-min {
                left: 9px;
                transition-delay: 0.15s;
            }
            .dot-max {
                left: 15px;
                transition-delay: 0.3s;
            }
        }
    }
    
    
    
    @media (min-width: 45em){
        grid-column-gap: 1rem;

        section{
            grid-area: auto / 1 / auto / 8;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-column-gap: 1rem;
            grid-row-gap: 2rem;
        }
    }

    @media (min-width: 60em){
        section{
            grid-area: auto / 1 / auto / 7;
        }
    }
`;

const PortfolioListing = ({ setAnnotation, setAnnotationAlert }) => {

    const data = useStaticQuery(PORTFOLIO_ITEM_QUERY)

    return (
        <PortfolioListingWrapper>
            <header 
            data-sal="fade"
            data-sal-delay="0"
            data-sal-duration="250"
            data-sal-easing="ease-in"
            className="">
                <h2>Selected Projects</h2>
            </header>
            <section className="listing-container">
                {data.allPostsJson.edges.map((edge, index) => {
                    index = index + 1;
                    //var delay = ( index % 2 == 0) ? 50 : 0;

                    return(
                        <div data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="250"
                        data-sal-easing="ease-in"
                        className={`item-wrapper ${index}`} 
                        key={edge.node.id}>
                            {edge.node.url ?(
                            <a href={edge.node.url} target="_blank" rel="noopener noreferrer" onTouchStart={function(e){e.currentTarget.classList.add('touching'); }} onTouchEnd={function(e){e.currentTarget.classList.remove('touching'); }}>
                            <div className="item-browser-wrapper">
                                <div className="browser-bar">
                                    <div className="dot-close"></div>
                                    <div className="dot-min"></div>
                                    <div className="dot-max"></div>
                                </div>
                                <Img fluid={edge.node.image.src.childImageSharp.fluid} />
                            </div>
                        
                            <h3><span>&#8599;{edge.node.title}</span></h3>
                        </a>
                            ):(
                            <div onTouchStart={function(e){e.currentTarget.classList.add('touching'); }} onTouchEnd={function(e){e.currentTarget.classList.remove('touching'); }}>
                                <div className="item-browser-wrapper">
                                    <div className="browser-bar">
                                        <div className="dot-close"></div>
                                        <div className="dot-min"></div>
                                        <div className="dot-max"></div>
                                    </div>
                                    <Img fluid={edge.node.image.src.childImageSharp.fluid} />
                                </div>
                            
                                <h3><span>{edge.node.title}</span></h3>
                            </div>
                            )}
                            
                            
                            <p>{edge.node.description}</p>
                        </div>
                    )
                })}
            </section>
        </PortfolioListingWrapper>
    )
}

export default PortfolioListing
