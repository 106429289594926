import React, { useState } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PortfolioListing from "../components/index/PortfolioListing"
import Contact from "../components/Contact"

const IndexPage = ({ location }) => {
  // Declare a new state variable, which we'll call "count"
  const [annotation, setAnnotation] = useState(false);
  const [annotationAlert, setAnnotationAlert] = useState(false);

  return(
    <Layout location={location} annotation={annotation} setAnnotation={setAnnotation} annotationAlert={annotationAlert} setAnnotationAlert={setAnnotationAlert}>
      <SEO title="Home" />

      <PortfolioListing setAnnotation={setAnnotation} setAnnotationAlert={setAnnotationAlert}/>
      
      <Contact setAnnotation={setAnnotation} setAnnotationAlert={setAnnotationAlert}/>
    </Layout>
  )
  }

export default IndexPage
